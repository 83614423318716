<template>
	<!-- eslint-disable no-mixed-spaces-and-tabs -->
	<div id="main-wrapper">
		<header class="topbar" data-navbarbg="skin5">
        	<Header />
    	</header>
    	<aside class="left-sidebar" data-sidebarbg="skin5">
	        <!-- Sidebar scroll-->
	        <div class="scroll-sidebar">
	            <!-- Sidebar navigation-->
	            <nav class="sidebar-nav">
	            	<Navbar />
    	        </nav>
            <!-- End Sidebar navigation -->
	        </div>
	        <!-- End Sidebar scroll-->
	    </aside>
	    <div class="page-wrapper">
	    	<div class="page-breadcrumb">
                <div class="row">
                    <div class="col-12 d-flex no-block align-items-center">
                        <h4 class="page-title">Items Listing</h4>
                        <div class="ml-auto text-right">
                            <!-- <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Library</li>
                                </ol>
                            </nav> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Sales Cards  -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title m-b-0">Items</h5>
                            </div>
                            <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Id</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Unit of measurement</th>
                                      <th scope="col">Unit price</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="item in get_items" :key="item.id">
                                    <tr>
                                      <th scope="row">{{item.id}}</th>
                                      <td>{{item.name}}</td>
                                      <td>{{item.uom}}</td>
                                      <td>{{item.unit_price}}</td>
                                    </tr>                                    
                                  </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
            </div>
	    </div>

	    <Footer />
	</div>
	<!-- eslint-disable no-mixed-spaces-and-tabs -->
</template>

<script>
    /* eslint-disable no-mixed-spaces-and-tabs */
      import Header from '@/components/Header';
      import Navbar from '@/components/Nav';
      import Footer from '@/components/Footer';
    export default {
      name: 'items',
        components: {
          Header,
          Navbar,
          Footer
        },
        data() {
            return {
                data : [],
                errors: []
            }
        },
        computed: {
          get_items() {          
            return this.$store.getters.get_items;    
          }
        },
        mounted() {
          this.$store.dispatch("items");
        }
    }
    /* eslint-disable no-mixed-spaces-and-tabs */
</script>